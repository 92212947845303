import { ChangeDetectorRef, Component } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { AuthService } from '../../../auth/auth.service';
import { UtilsService } from '../../../core/services/utils.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { faHeart, faListCheck, faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '../../../core/services/toast.service';
import { CartService } from '../../../core/services/cart.service';
import { filter } from 'rxjs';
import { DataService } from '../../../core/services/data.service';
import { ResizeService } from '../../../core/services/resize.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  currentUrl: string = this.router.url;
  isMobileView = this.resize.isMobileView();
  isNavbarCollapsed = true;
  isSearchBarCollapsed = true;
  showCart = false;
  profile: any;
  searchForm!: FormGroup;

  isAuthenticated = false;

  icons = { faRightFromBracket, faListCheck, faUser, faHeart };
  searchQuery: string = '';
  cartQuantity = 0;

  role: string = '';
  currentRoute: string = '';
  isDashboard: boolean = true;

  constructor(
    private api: ApiService,
    private auth: AuthService,
    private utils: UtilsService,
    private router: Router,
    private cart: CartService,
    private cdr: ChangeDetectorRef,
    private data: DataService,
    private resize: ResizeService,
  ) {
  }
  
  ngOnInit() {
    this.resize.resize$.subscribe((isMobile: boolean) => {
      this.isMobileView = isMobile;
    });

    this.data.searchQuery$.subscribe((query: string) => {
      this.searchQuery = query;
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.currentRoute = this.router.url;
      this.isDashboard = this.isDash(this.currentRoute);
    });

    this.searchForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
    });

    this.auth.isAuthenticated$.subscribe({
      next: (isAuthenticated: boolean) => {
        this.isAuthenticated = isAuthenticated;
        if (isAuthenticated) {
          this.auth.theRole$.subscribe({
            next: (role: string) => {
              this.role = role;
              if (role) {
                if (role === 'Management') {
                  this.getManagementProfile();
                } else {
                  this.getProfile();
                }
              }
            },
            error: (error: any) => {
              console.error('Error in header component:', error);
            }
          });
        }
      },
      error: (error: any) => {
        console.error('Error in header component:', error);
      }
    });

    this.cart.cartQuantity$.subscribe({
      next: (quantity: number) => {
        this.cartQuantity = quantity;
      },
      error: (error: any) => {
        console.error('Error in header component:', error);
      }
    });
  }

  isDash(route: string): boolean {
    const routes = ['/dashboard', '/brands', '/profile', '/orders']
    return routes.some(r => routes.some(r => route.startsWith(r)));
  }

  getProfile() {
    this.api.getProfile().subscribe({
      next: (response: any) => {
        this.profile = response.data;
        localStorage.setItem('profile', JSON.stringify(this.profile));
        setTimeout(() => {
          if (
            !this.profile.isPhoneVerified && 
            (this.profile.activeRole === 'Franchisee' || this.profile.activeRole === 'Franchisor') &&
            !this.router.url.startsWith('/express')
          ) {
            this.router.navigate(['/profile/verify-otp'], {
              state: { ...this.profile }
            });
          }
        }, 1000);
      },
      error: (error: any) => {
        console.error('Error in header component:', error);
      }
    })
  }

  getManagementProfile() {
    this.api.getManagementProfile().subscribe({
      next: (response: any) => {
        this.profile = response.data;
      },
      error: (error: any) => {
        console.error('Error in header component:', error);
      }
    })
  }

  toggleNavbar() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
    this.isSearchBarCollapsed = true;
  }

  toggleSearchBar() {
    this.isSearchBarCollapsed = !this.isSearchBarCollapsed;
    this.isNavbarCollapsed = true;
  }

  openCart() {
    this.showCart = !this.showCart;
    this.router.navigate(['/cart']);
    if (!this.isNavbarCollapsed) {
      this.toggleNavbar();
    }
    if (!this.isSearchBarCollapsed) {
      this.toggleSearchBar();
    }
  }

  openOrders() {
    this.router.navigate(['/order']);
  }

  openProfile() {
    this.router.navigate(['/profile']);
  }

  openWishlist() {
    this.router.navigate(['/wishlist']);
  }

  openBrands() {
    this.router.navigate(['/brands']);
  }

  logout() {
    this.auth.logout();
    this.profile = null;
    this.data.triggerRefresh();
    this.router.navigate(['/home'], { queryParams: { _: new Date().getTime() }, replaceUrl: true });
    this.toggleNavbar();
  }

  onSearch(event: any) {
    this.data.setSearchQuery(this.searchQuery);
    this.router.navigate(['/catalog'], { queryParams: { search: this.searchQuery } });
    this.toggleSearchBar();
  }

  onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.value === '') {
      this.clearSearch();
    }
  }

  clearSearch() {
    this.searchQuery = '';
    this.data.clearSearchQuery();
    this.router.navigate(['/catalog']);
    this.toggleSearchBar();
  }

}
