import { Component } from '@angular/core';

@Component({
  selector: 'app-bank-verification-modal',
  templateUrl: './bank-verification-modal.component.html',
  styleUrl: './bank-verification-modal.component.scss'
})
export class BankVerificationModalComponent {

}
