import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataUrl, NgxImageCompressService } from 'ngx-image-compress';
import { ToastService } from '../../../core/services/toast.service';
import { ApiService } from '../../../core/services/api.service';
import { ResizeService } from '../../../core/services/resize.service';
import { HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-npwp-verification-modal',
  templateUrl: './npwp-verification-modal.component.html',
  styleUrl: './npwp-verification-modal.component.scss'
})
export class NpwpVerificationModalComponent {
  uploadForm!: FormGroup;
  uploadProgress: number = 0;
  isLoading: boolean = false;
  npwpPreview: string = '';
  ktpUid: string = '';
  imgSize: number = 5;
  npwpPreviewBlobUrl: SafeUrl | null = null;
  isMobileView = false;

  constructor(
    public modal: NgbModal,
    public activeModal: NgbActiveModal, 
    private imageCompress: NgxImageCompressService,
    private toast: ToastService,
    private api: ApiService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private resize: ResizeService,
  ) { }

  ngOnInit(): void {
    this.resize.resize$.subscribe((isMobile: boolean) => {
      this.isMobileView = isMobile;
    });

    this.uploadForm = this.fb.group({
      ktp: ['', [Validators.required, Validators.minLength(16), Validators.maxLength(16)]],
      ktpFile: ['', [Validators.required]],
      ktpUpload: [''],
      npwp: ['', [Validators.required, Validators.minLength(15), Validators.maxLength(16)]],
      npwpFile: ['', [Validators.required]],
      npwpUpload: [''],
      accountBank: ['', [Validators.required]],
      accountNumber: ['', [Validators.required]],
      accountName: ['', [Validators.required]],
    });
  }

  isControlInvalid(controlName: string, errorType?: string): boolean | undefined {
    const control = this.uploadForm.get(controlName);

    if (!control) {
      return undefined;
    }

    if (errorType) {
      return control.hasError(errorType) && (control.touched || control.dirty);
    } else {
      return control.invalid && (control.touched || control.dirty);
    }
  }

  private apiUrl = environment.apiUrl;

  async documentUpload(type: string) {
    this.isLoading = true;
    const { image, orientation } = await this.imageCompress.uploadFile();
    const initialSize = this.imageCompress.byteCount(image);
    console.log('Initial size in bytes:', initialSize);

    let result = image;
    const maxSizeInBytes = this.imgSize * 1024 * 1024;
    if (initialSize > maxSizeInBytes) {
      console.warn('Image is too large, compressing...');
      result = await this.imageCompress.compressFile(image, orientation);
      console.log('Current size in bytes:', this.imageCompress.byteCount(result));
      while (this.imageCompress.byteCount(result) > maxSizeInBytes) {
        console.warn('Re-compressing image...');
        result = await this.imageCompress.compressFile(result, orientation);
        console.log('Current size in bytes:', this.imageCompress.byteCount(result));
      }
    } else {
      console.log('Image size is within limits of ' + this.imgSize + 'MB, no need to compress.');
    }

    const blob = this.dataURItoBlob(result);
    const blobUrl = URL.createObjectURL(blob);

    this.npwpPreview = result;
    this.npwpPreviewBlobUrl = this.sanitizeUrl(blobUrl);
    this.uploadCompressedImage(result, 'npwpFile').subscribe({
      next: (filePath: string) => {
        this.uploadForm.get('npwpFile')?.setValue(filePath);
      },
      error: (error: any) => {
        console.error(error);
        this.isLoading = false;
        this.toast.show({ textOrHtml: 'NPWP photo upload failed. Please try again with a different image.', classname: 'bg-danger text-white', delay: 2000 });
      },
      complete: () => {
        this.isLoading = false;
        this.uploadProgress = 0;
        this.uploadForm.get('npwpUpload')?.enable();
      }
    });
  }

  uploadCompressedImage(compressedImage: DataUrl, type: string) {
    const blob = this.dataURItoBlob(compressedImage);
    const file = new File([blob], 'compressed-image.jpg', { type: 'image/jpeg' });
    const formData = new FormData();
    formData.append('file', file);
    if (type === 'npwpFile') {
      formData.append('id', 'npwp');
    }
    const req = new HttpRequest('POST', `${this.apiUrl}/private/upload`, formData, {
      reportProgress: true,
      withCredentials: true
    });
    this.isLoading = true;

    return this.api.uploadWithProgress(req).pipe(
      map((event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total) {
            this.uploadProgress = Math.round((100 * event.loaded) / event.total);
          }
          this.cdr.detectChanges();
        } else if (event instanceof HttpResponse) {
          if (this.uploadProgress === 100) {
            this.isLoading = false;
            setTimeout(() => {
              this.uploadProgress = 0;
            }, 1000);
          }
          return event.body.data;
        }
      })
    );
  }

  private dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }

  updateDocuments(docType?: string) {
    let data = {};
    if (this.uploadForm.controls['npwpFile'].invalid) {
      this.toast.show({ textOrHtml: 'Please fill all the fields (NPWP)', classname: 'bg-danger text-white', delay: 1000 });
      return;
    }
    data = {
      npwp: this.uploadForm.get('npwp')?.value,
      npwpFile: this.uploadForm.get('npwpFile')?.value,
    };
    this.api.updateProfile(data).subscribe({
      next: (response: any) => {
        this.toast.show({ textOrHtml: 'Documents updated successfully!', classname: 'bg-success text-white', delay: 1000 });
        this.activeModal.close();
      },
      error: (error: any) => {
        console.error(error);
      },
    });
  }

  sanitizeUrl(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
