import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataUrl, NgxImageCompressService } from 'ngx-image-compress';
import { ToastService } from '../../../core/services/toast.service';
import { HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../core/services/api.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { map } from 'rxjs';
import { ResizeService } from '../../../core/services/resize.service';

@Component({
  selector: 'app-ktp-verification-modal',
  templateUrl: './ktp-verification-modal.component.html',
  styleUrl: './ktp-verification-modal.component.scss'
})
export class KtpVerificationModalComponent {
  ktpForm!: FormGroup;
  uploadForm!: FormGroup;
  uploadProgress: number = 0;
  isLoading: boolean = false;
  ktpPreview: string = '';
  ktpUid: string = '';
  imgSize: number = 5;
  ktpPreviewBlobUrl: SafeUrl | null = null;
  isMobileView = false;

  constructor(
    public modal: NgbModal,
    public activeModal: NgbActiveModal, 
    private imageCompress: NgxImageCompressService,
    private toast: ToastService,
    private api: ApiService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private resize: ResizeService,
  ) { }

  ngOnInit(): void {
    this.resize.resize$.subscribe((isMobile: boolean) => {
      this.isMobileView = isMobile;
    });
    this.uploadForm = this.fb.group({
      ktp: ['', [Validators.required, Validators.minLength(16), Validators.maxLength(16)]],
      ktpFile: ['', [Validators.required]],
      ktpUpload: [''],
      npwp: ['', [Validators.required, Validators.minLength(15), Validators.maxLength(16)]],
      npwpFile: ['', [Validators.required]],
      npwpUpload: [''],
      accountBank: ['', [Validators.required]],
      accountNumber: ['', [Validators.required]],
      accountName: ['', [Validators.required]],
    });
    this.ktpForm = this.fb.group({
      nIK: ['', [Validators.required, Validators.minLength(16), Validators.maxLength(16)]],
      file: ['', [Validators.required]],
      ktpUpload: [''],
      name: ['', [Validators.required]],
      birthPlace: ['', [Validators.required]],
      birthDay: ['', [Validators.required, Validators.pattern(/^\d{2}-\d{2}-\d{4}$/)]],
      sex: ['', [Validators.required]],
      address: ['', [Validators.required]],
      rTRW: ['', [Validators.required]],
      village: ['', [Validators.required]],
      district: ['', [Validators.required]],
      regency: ['', [Validators.required]],
      province: ['', [Validators.required]],
    });
  }

  async documentUpload() {
    this.isLoading = true;
    const { image, orientation } = await this.imageCompress.uploadFile();
    const initialSize = this.imageCompress.byteCount(image);
    console.log('Initial size in bytes:', initialSize);

    let result = image;
    const maxSizeInBytes = this.imgSize * 1024 * 1024;
    if (initialSize > maxSizeInBytes) {
      console.warn('Image is too large, compressing...');
      result = await this.imageCompress.compressFile(image, orientation);
      console.log('Current size in bytes:', this.imageCompress.byteCount(result));
      while (this.imageCompress.byteCount(result) > maxSizeInBytes) {
        console.warn('Re-compressing image...');
        result = await this.imageCompress.compressFile(result, orientation);
        console.log('Current size in bytes:', this.imageCompress.byteCount(result));
      }
    } else {
      console.log('Image size is within limits of ' + this.imgSize + 'MB, no need to compress.');
    }

    const blob = this.dataURItoBlob(result);
    const blobUrl = URL.createObjectURL(blob);

    this.ktpPreview = result;
    this.ktpPreviewBlobUrl = this.sanitizeUrl(blobUrl);
    this.uploadCompressedImage(result, 'ktpFile').subscribe({
      next: (response: any) => {
        const data = response;
        if (data && data.nik) {
          this.ktpForm.patchValue({
            nIK: data.nik === 'NOT DETECTED' ? '' : data.nik,
            name: data.nama === 'NOT DETECTED' ? '' : data.nama,
            birthPlace: data.tempat_lahir === 'NOT DETECTED' ? '' : data.tempat_lahir,
            birthDay: data.tgl_lahir === 'NOT DETECTED' ? '' : data.tgl_lahir.split('-')[2] + '-' + data.tgl_lahir.split('-')[1] + '-' + data.tgl_lahir.split('-')[0],
            sex: data.jenis_kelamin === 'NOT DETECTED' ? '' : data.jenis_kelamin,
            address: data.alamat.name === 'NOT DETECTED' ? '' : data.alamat.name,
            rTRW: data.alamat.rt_rw === 'NOT DETECTED' ? '' : data.alamat.rt_rw,
            village: data.alamat.kel_desa === 'NOT DETECTED' ? '' : data.alamat.kel_desa,
            district: data.alamat.kecamatan === 'NOT DETECTED' ? '' : data.alamat.kecamatan,
            regency: data.alamat.kabupaten === 'NOT DETECTED' ? '' : data.alamat.kabupaten,
            province: data.alamat.provinsi === 'NOT DETECTED' ? '' : data.alamat.provinsi,
            file: data.path
          });
          this.ktpUid = data.uid;
          this.uploadForm.get('ktpFile')?.setValue(data.path);
        }
      },
      error: (error: any) => {
        console.error(error);
        this.uploadProgress = 0;
        this.isLoading = false;
        this.toast.show({ textOrHtml: 'KTP photo upload failed. Please try again with a different image.', classname: 'bg-danger text-white', delay: 2000 });
      },
      complete: () => {
        this.isLoading = false;
        this.uploadProgress = 0;
      }
    });
  }

  private apiUrl = environment.apiUrl;

  uploadCompressedImage(compressedImage: DataUrl, type: string) {
    const blob = this.dataURItoBlob(compressedImage);
    const file = new File([blob], 'compressed-image.jpg', { type: 'image/jpeg' });
    const formData = new FormData();
    formData.append('file', file);
    if (type === 'npwpFile') {
      formData.append('id', 'npwp');
    }
    const req = new HttpRequest('POST', type === 'ktpFile' ? `${this.apiUrl}/private/uploadKTP` : `${this.apiUrl}/private/upload`, formData, {
      reportProgress: true,
      withCredentials: true
    });
    this.isLoading = true;

    return this.api.uploadWithProgress(req).pipe(
      map((event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total) {
            this.uploadProgress = Math.round((100 * event.loaded) / event.total);
          }
          this.cdr.detectChanges();
        } else if (event instanceof HttpResponse) {
          if (this.uploadProgress === 100) {
            this.isLoading = false;
            setTimeout(() => {
              this.uploadProgress = 0;
            }, 1000);
          }
          return event.body.data;
        }
      })
    );
  }

  private dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }

  sanitizeUrl(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  updateDocuments(docType?: string) {
    let data = {};
    if (docType === 'bank') {
      if (this.uploadForm.controls['accountBank'].invalid || this.uploadForm.controls['accountNumber'].invalid || this.uploadForm.controls['accountName'].invalid) {
        this.toast.show({ textOrHtml: 'Please fill all the fields (Bank Account)', classname: 'bg-danger text-white', delay: 1000 });
        return;
      }
      data = {
        accountBank: this.uploadForm.get('accountBank')?.value,
        accountNumber: this.uploadForm.get('accountNumber')?.value,
        accountName: this.uploadForm.get('accountName')?.value,
      };
      this.api.updateProfile(data).subscribe({
        next: (response: any) => {
          this.toast.show({ textOrHtml: 'Documents updated successfully!', classname: 'bg-success text-white', delay: 1000 });
          this.activeModal.close();
        },
        error: (error: any) => {
          console.error(error);
        },
      });
    } else if (docType === 'ktp') {
      if (this.ktpForm.invalid) {
        this.ktpForm.markAllAsTouched();
        this.toast.show({ textOrHtml: 'Please fill all the fields (KTP)', classname: 'bg-danger text-white', delay: 1000 });
        return;
      }
      data = this.ktpForm.value;
      this.api.updateKTP(this.ktpUid, data).subscribe({
        next: (response: any) => {
          this.toast.show({ textOrHtml: 'KTP updated successfully!', classname: 'bg-success text-white', delay: 1000 });
          this.activeModal.close();
        },
        error: (error: any) => {
          console.error(error);
        },
      });
    } else if (docType === 'npwp') {
      if (this.uploadForm.controls['npwpFile'].invalid) {
        this.toast.show({ textOrHtml: 'Please fill all the fields (NPWP)', classname: 'bg-danger text-white', delay: 1000 });
        return;
      }
      data = {
        npwp: this.uploadForm.get('npwp')?.value,
        npwpFile: this.uploadForm.get('npwpFile')?.value,
      };
      this.api.updateProfile(data).subscribe({
        next: (response: any) => {
          this.toast.show({ textOrHtml: 'Documents updated successfully!', classname: 'bg-success text-white', delay: 1000 });
          this.activeModal.close();
        },
        error: (error: any) => {
          console.error(error);
        },
      });
    } else if (docType === 'all') {
      data = {
        accountBank: this.uploadForm.get('accountBank')?.value,
        accountNumber: this.uploadForm.get('accountNumber')?.value,
        accountName: this.uploadForm.get('accountName')?.value,
      }
      this.api.updateProfile(data).subscribe({
        next: (response: any) => {
          this.toast.show({ textOrHtml: 'Documents updated successfully!', classname: 'bg-success text-white', delay: 1000 });
          this.activeModal.close(response.data);
        },
        error: (error: any) => {
          console.error(error);
          this.activeModal.close();
        },
      });
    }
  }

  isKtpControlInvalid(controlName: string, errorType?: string): boolean | undefined {
    const control = this.ktpForm.get(controlName);

    if (!control) {
      return undefined;
    }

    if (errorType) {
      return control.hasError(errorType) && (control.touched || control.dirty);
    } else {
      return control.invalid && (control.touched || control.dirty);
    }
  }

}
