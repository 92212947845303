<div class="modal-header bg-primary text-white">
  <h6 class="modal-title" id="modal-basic-title">KTP Verification</h6>
  <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <!-- Content for KTP Verification -->
  <form [formGroup]="ktpForm">
    <div>
      <div class="mb-3">
        <label class="fw-bold">KTP File</label>
        <br>
        <div class="col-12">
          <div class="mt-2" *ngIf="uploadProgress > 0">
            <ngb-progressbar class="mb-3" type="primary" [value]="uploadProgress"
              aria-valuemin="0"
              aria-valuemax="100"
              [animated]="true"
              [striped]="false"
              [showValue]="false"
              [height]="'20px'"
            >
              <span class="progress-bar-text">
                <small>{{ uploadProgress }}% uploaded</small>
              </span>
            </ngb-progressbar>
          </div>
        </div>
        <!-- <input type="file" formControlName="ktpUpload" class="form-control mb-3" (change)="onFileChange($event, 'ktpFile')" [ngClass]="{'is-invalid': isKtpControlInvalid('path')}" [readonly]="isLoading"> -->
        <button class="btn btn-primary mb-3" [disabled]="isLoading" type="button" (click)="documentUpload()">
          <span *ngIf="isLoading" class="spinner-border spinner-border-sm me-2"></span>
          {{ isLoading ? 'Uploading...' : 'Upload KTP Photo' }}
        </button>
        <!-- <img *ngIf="ktpPreview" [src]="ktpPreview" alt="KTP preview" class="w-100"> -->
        <img *ngIf="ktpPreviewBlobUrl" [src]="ktpPreviewBlobUrl" alt="KTP preview" class="w-100">
        <small *ngIf="isLoading" class="text-primary d-flex justify-content-center align-items-center gap-3 mt-3">
          <i class="spinner-border"></i>
          Reading KTP Data...
        </small>
        <div *ngIf="isKtpControlInvalid('path', 'required')" class="invalid-feedback">
          Please upload a legible and valid KTP file in image format (JPG, PNG, or JPEG).
        </div>
      </div>
      <div class="mb-3">
        <label class="fw-bold">KTP Number <sup class="text-danger">*</sup></label>
        <input type="text" formControlName="nIK" class="form-control" [ngClass]="{'is-invalid': isKtpControlInvalid('nIK')}" [readonly]="isLoading" />
        <div *ngIf="isKtpControlInvalid('nIK', 'required')" class="invalid-feedback">
          KTP number is required.
        </div>
        <div *ngIf="isKtpControlInvalid('nIK', 'minlength')" class="invalid-feedback">
          KTP number must be 16 digits long.
        </div>
        <div *ngIf="isKtpControlInvalid('nIK', 'maxlength')" class="invalid-feedback">
          KTP number must be 16 digits long.
        </div>
      </div>
      <div class="mb-3">
        <label class="fw-bold">Name <sup class="text-danger">*</sup></label>
        <input type="text" formControlName="name" class="form-control" [ngClass]="{'is-invalid': isKtpControlInvalid('name')}" [readonly]="isLoading" />
        <div *ngIf="isKtpControlInvalid('name', 'required')" class="invalid-feedback">
          Name is required.
        </div>
      </div>
      <div class="mb-3">
        <label class="fw-bold">Birth Place <sup class="text-danger">*</sup></label>
        <input type="text" formControlName="birthPlace" class="form-control" [ngClass]="{'is-invalid': isKtpControlInvalid('birthPlace')}" [readonly]="isLoading" />
        <div *ngIf="isKtpControlInvalid('birthPlace', 'required')" class="invalid-feedback">
          Birth Place is required.
        </div>
      </div>
      <div class="mb-3">
        <label class="fw-bold">Birth Date <sup class="text-danger">*</sup></label>
        <input type="text" formControlName="birthDay" class="form-control" [ngClass]="{'is-invalid': isKtpControlInvalid('birthDay')}" placeholder="dd-mm-yyyy" [readonly]="isLoading" />
        <div *ngIf="isKtpControlInvalid('birthDay', 'required')" class="invalid-feedback">
          Birth Date is required.
        </div>
        <div *ngIf="isKtpControlInvalid('birthDay', 'pattern')" class="invalid-feedback">
          Birth Date must be in the format of dd-mm-yyyy.
        </div>
      </div>
      <div class="mb-3">
        <label class="fw-bold">Gender <sup class="text-danger">*</sup></label>
        <select formControlName="sex" class="form-control" [ngClass]="{'is-invalid': isKtpControlInvalid('sex')}">
          <option value="" disabled selected>Select your gender</option>
          <option value="LAKI-LAKI">LAKI-LAKI</option>
          <option value="PEREMPUAN">PEREMPUAN</option>
        </select>
        <div *ngIf="isKtpControlInvalid('sex', 'required')" class="invalid-feedback">
          Gender is required.
        </div>
      </div>
      <div class="mb-3">
        <label class="fw-bold">Address <sup class="text-danger">*</sup></label>
        <input type="text" formControlName="address" class="form-control" [ngClass]="{'is-invalid': isKtpControlInvalid('address')}" [readonly]="isLoading" />
        <div *ngIf="isKtpControlInvalid('address', 'required')" class="invalid-feedback">
          Address is required.
        </div>
      </div>
      <div class="mb-3">
        <label class="fw-bold">RT/RW <sup class="text-danger">*</sup></label>
        <input type="text" formControlName="rTRW" class="form-control" [ngClass]="{'is-invalid': isKtpControlInvalid('rTRW')}" [readonly]="isLoading" />
        <div *ngIf="isKtpControlInvalid('rTRW', 'required')" class="invalid-feedback">
          R/T/RW is required.
        </div>
      </div>
      <div class="mb-3">
        <label class="fw-bold">Village (Kelurahan/Desa) <sup class="text-danger">*</sup></label>
        <input type="text" formControlName="village" class="form-control" [ngClass]="{'is-invalid': isKtpControlInvalid('village')}" [readonly]="isLoading" />
        <div *ngIf="isKtpControlInvalid('village', 'required')" class="invalid-feedback">
          Village is required.
        </div>
      </div>
      <div class="mb-3">
        <label class="fw-bold">District (Kecamatan) <sup class="text-danger">*</sup></label>
        <input type="text" formControlName="district" class="form-control" [ngClass]="{'is-invalid': isKtpControlInvalid('district')}" [readonly]="isLoading" />
        <div *ngIf="isKtpControlInvalid('district', 'required')" class="invalid-feedback">
          District is required.
        </div>
      </div>
      <div class="mb-3">
        <label class="fw-bold">Regency (Kabupaten/Kotamadya) <sup class="text-danger">*</sup></label>
        <input type="text" formControlName="regency" class="form-control" [ngClass]="{'is-invalid': isKtpControlInvalid('regency')}" [readonly]="isLoading" />
        <div *ngIf="isKtpControlInvalid('regency', 'required')" class="invalid-feedback">
          Regency is required.
        </div>
      </div>
      <div class="mb-3">
        <label class="fw-bold">Province <sup class="text-danger">*</sup></label>
        <input type="text" formControlName="province" class="form-control" [ngClass]="{'is-invalid': isKtpControlInvalid('province')}" [readonly]="isLoading" />
        <div *ngIf="isKtpControlInvalid('province', 'required')" class="invalid-feedback">
          Province is required.
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark flex-fill" (click)="activeModal.close('Close click')">Close</button>
  <button type="button" class="btn btn-primary flex-fill" (click)="updateDocuments('ktp');">Save</button>
</div>