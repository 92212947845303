<div class="modal-header bg-primary text-white">
  <h6 class="modal-title" id="modal-basic-title">NPWP Verification</h6>
  <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <!-- Content for NPWP Verification -->
  <form [formGroup]="uploadForm">
    <div>
      <div class="mb-3">
        <label class="fw-bold">NPWP Number</label>
        <input type="text" formControlName="npwp" class="form-control" [ngClass]="{'is-invalid': isControlInvalid('npwp')}" />
        <div *ngIf="isControlInvalid('npwp', 'required')" class="invalid-feedback">
          NPWP number is required
        </div>
        <div *ngIf="isControlInvalid('npwp', 'minlength')" class="invalid-feedback">
          NPWP number minimum length is 15 digits long.
        </div>
        <div *ngIf="isControlInvalid('npwp', 'maxlength')" class="invalid-feedback">
          NPWP number maximum length is 16 digits long.
        </div>
      </div>
      <div class="mb-3">
        <label class="fw-bold">NPWP File</label>
        <br>
        <div class="col-12">
          <div class="mt-2" *ngIf="uploadProgress > 0">
            <ngb-progressbar class="mb-3" type="primary" [value]="uploadProgress"
              aria-valuemin="0"
              aria-valuemax="100"
              [animated]="true"
              [striped]="false"
              [showValue]="false"
              [height]="'20px'"
            >
              <span class="progress-bar-text">
                <small>{{ uploadProgress }}% uploaded</small>
              </span>
            </ngb-progressbar>
          </div>
        </div>
        <button class="btn btn-primary mb-3" type="button" (click)="documentUpload('npwpFile')" [disabled]="isLoading">
          <span *ngIf="isLoading" class="spinner-border spinner-border-sm me-2"></span>
          {{ isLoading ? 'Uploading...' : 'Upload NPWP Photo' }}
        </button>
        <!-- <input type="file" formControlName="npwpUpload" class="form-control mb-3" (change)="onFileChange($event, 'npwpFile')" [ngClass]="{'is-invalid': isControlInvalid('npwpFile')}"> -->
        <!-- <img *ngIf="npwpPreview" [src]="npwpPreview" alt="NPWP preview" class="w-100"> -->
        <img *ngIf="npwpPreviewBlobUrl" [src]="npwpPreviewBlobUrl" alt="NPWP preview" class="w-100">
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark flex-fill" (click)="activeModal.close('Close click')">Close</button>
  <button type="button" class="btn btn-primary flex-fill" (click)="updateDocuments('npwp');">Save</button>
</div>